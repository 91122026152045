module.exports = {
  // deepcode ignore HardcodedNonCryptoSecret: This is not a secret, it's a public key.
  apiKey: "AIzaSyDLbQzXLi25dP1kMLxNwz09k-naYsd77ns",
  authDomain: "telltouch-web.firebaseapp.com",
  projectId: "telltouch-web",
  storageBucket: "telltouch-web.appspot.com",
  messagingSenderId: "113989099375",
  appId: "1:113989099375:web:11b631cc68344fa916929a",
  measurementId: "G-ZMCFQH3HN2",
};
