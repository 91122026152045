class Footer extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = `
<div id="footer" class="bg-dark d-flex align-items-center">
  <div class="container-fluid py-5">
    <div class="row justify-content-center">
      <div class="col-10 col-sm-5 col-lg-4 col-xxl-3 d-flex align-items-center justify-content-center">
        <div class="text-center">
          <img class="img-fluid my-2" width="228" height="80" src="/assets/images/brand/logo.svg" alt="Tell Touch logo" />
          <div class="row justify-content-center g-3">
            <div class="col-6">
              <a href="https://apps.apple.com/us/app/tell-touch/id1465370268?ls=1"><img class="img-fluid" src="/assets/images/theme/appstore-white.svg" width="160" height="60" alt="App Store" loading="lazy" /></a>
            </div>
            <div class="col-6">
              <a href="https://play.google.com/store/apps/details?hl=en_AU&amp;id=au.com.slay.telltouch"><img class="img-fluid" src="/assets/images/theme/googleplay-white.svg" width="160" height="60" alt="Google Play Store" loading="lazy" /></a>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-end mt-3 mb-3 text-mid lh-1 w-100">
            <div class="text-nowrap">
              <a href="https://www.instagram.com/telltouch/" title="Instagram" rel="noreferrer" class="link-mid d-inline-flex me-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                </svg>
              </a>
              <a href="https://twitter.com/tell_touch" title="Twitter" rel="noreferrer" class="link-mid d-inline-flex me-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/telltouch" title="LinkedIn" rel="noreferrer" class="link-mid d-inline-flex me-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                </svg>
              </a>
            </div>
            <p class="text-mid text-end mb-0 small fs-8">
              Made with
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill text-danger" viewbox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
              </svg>
              in 
              <span class="text-nowrap">Melbourne, Australia</span>
            </p>
          </div>
          <div class="text-start me-lg-n5 me-xl-0">
            <p class="mb-1 d-flex">
              <img class="me-1" src="/assets/images/theme/flag-aboriginal.svg" width="25" height="15" alt="Aboriginal flag" />
              <img class="me-1" src="/assets/images/theme/flag-torres-strait-islander.svg" width="25" height="15" alt="Torres Strait Islander flag" />
            </p>
            <p class="text-mid small fs-8 mb-0 lh-sm">Tell Touch acknowledges and pays respect to the past, present, and emerging Traditional Custodians and Elders of this nation and the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 offset-sm-1 col-md-2 col-lg-2 offset-lg-2 d-flex align-items-center">
        <ul class="nav flex-column fs-5 text-center text-sm-start w-100 mb-0 mt-5 mt-sm-0">
          <li class="nav-item mb-1"><a href="https://blog.telltouch.com.au" class="nav-link p-sm-0 link-light text-nowrap" target="_blank">
            Blog 
            <sup>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-box-arrow-up-right opacity-50" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
              </svg>
            </sup>
          </a></li>
          <li class="nav-item mb-1"><a href="/faqs" class="nav-link p-sm-0 link-light text-nowrap">FAQs</a></li>
          <li class="nav-item mb-1"><a href="/pricing" class="nav-link p-sm-0 link-light text-nowrap">Pricing</a></li>
          <li class="nav-item mb-1"><a href="/about" class="nav-link p-sm-0 link-light text-nowrap">About Us</a></li>
          <li class="nav-item mb-1"><a href="/contact" class="nav-link p-sm-0 link-light text-nowrap">Contact Us</a></li>
          <li class="nav-item mb-1"><a href="/languages" class="nav-link p-sm-0 link-light text-nowrap">Languages</a></li>
          <li class="nav-item mb-1"><a href="/privacy" class="nav-link p-sm-0 link-light text-nowrap">Privacy Policy</a></li>
          <li class="nav-item mb-1"><a href="/terms" class="nav-link p-sm-0 link-light text-nowrap">Terms &amp; Conditions</a></li>
          <li class="nav-item text-mid mt-2 small text-nowrap">© 2024 Tell Touch Pty Ltd</li>
        </ul>
      </div>
    </div>
  </div>
</div>
`;
  }
}

customElements.define("footer-component", Footer);
